/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/camelcase */
import { TemplateHomeEnglishDataQuery } from '../../../gatsby-types'
import { HomeSliceType, IHomeContent, IHomeSlice } from '../home/types'

export function transformHomeEnglishNode(
  node: TemplateHomeEnglishDataQuery['prismicHomeEnglish']
): IHomeContent & { slices: IHomeSlice[] } {
  if (!node) throw new Error('Invalid home_english node.')

  const { data } = node
  if (!data) throw new Error('Invalid home_english node.')

  const { name, body } = data
  if (!name || !name.text || !body) {
    throw new Error('Invalid home_english node.')
  }

  return {
    name: name.text,
    slices: body.map((slice) => {
      if (!slice) throw new Error('Invalid home_english node.')

      // TODO: fix using discriminated unions from "gatsby-types-2.ts"
      const { slice_type, primary, items } = slice

      switch (slice_type) {
        case 'main_title_block': {
          if (!primary || !primary.title || !primary.title.html || !primary.subtitle || !primary.subtitle.text) {
            throw new Error('Invalid home_english main_title_block slice.')
          }

          return {
            type: HomeSliceType.MAIN_TITLE_BLOCK,
            content: {
              title: primary.title.html,
              subtitle: primary.subtitle.text,
              leftLink:
                primary.leftlinklabel && primary.leftlinklabel.text && primary.leftlinkurl && primary.leftlinkurl.url
                  ? { label: primary.leftlinklabel.text, url: primary.leftlinkurl.url }
                  : undefined,
              rightLink:
                primary.rightlinklabel &&
                primary.rightlinklabel.text &&
                primary.rightlinkurl &&
                primary.rightlinkurl.url
                  ? { label: primary.rightlinklabel.text, url: primary.rightlinkurl.url }
                  : undefined,
            },
          }
        }
        case 'inline_block': {
          if (
            !primary ||
            !primary.title ||
            !primary.title.text ||
            !primary.subtitle ||
            !primary.subtitle.text ||
            !primary.text ||
            !primary.text.html
          ) {
            throw new Error('Invalid home_english inline_block slice.')
          }

          return {
            type: HomeSliceType.INLINE_BLOCK,
            content: {
              title: primary.title.text,
              subtitle: primary.subtitle.text,
              text: primary.text.html,
              link:
                primary.linklabel && primary.linklabel.text && primary.linkurl && primary.linkurl.url
                  ? { label: primary.linklabel.text, url: primary.linkurl.url }
                  : undefined,
            },
          }
        }
        case 'banner': {
          if (!primary || !primary.video || !primary.video.url) {
            throw new Error('Invalid home_english inline_block slice.')
          }

          return {
            type: HomeSliceType.BANNER,
            content: { videoUrl: primary.video.url },
          }
        }
        case 'edtech_block': {
          if (
            !primary ||
            !primary.lefttitle ||
            !primary.lefttitle.text ||
            !primary.lefttext ||
            !primary.lefttext.html ||
            !primary.righttitle ||
            !primary.righttitle.text ||
            !primary.righttext ||
            !primary.righttext.html
          ) {
            throw new Error('Invalid home_english edtech_block slice.')
          }

          return {
            type: HomeSliceType.EDTECH_BLOCK,
            content: {
              left: {
                title: primary.lefttitle.text,
                text: primary.lefttext.html,
              },
              right: {
                title: primary.righttitle.text,
                text: primary.righttext.html,
              },
            },
          }
        }
        case 'project_cards': {
          if (
            !primary ||
            !primary.lefttitle ||
            !primary.lefttitle.text ||
            !primary.subtitle ||
            !primary.subtitle.text ||
            !primary.lefttext ||
            !primary.lefttext.text ||
            !primary.righttext ||
            !primary.righttext.html ||
            !items
          ) {
            throw new Error('Invalid home_english project_cards slice.')
          }

          return {
            type: HomeSliceType.PROJECT_CARDS,
            content: {
              left: {
                title: primary.lefttitle.text,
                subtitle: primary.subtitle.text,
                text: primary.lefttext.text,
              },
              right: {
                text: primary.righttext.html,
                link:
                  primary.linklabel && primary.linklabel.text && primary.linkurl && primary.linkurl.url
                    ? { label: primary.linklabel.text, url: primary.linkurl.url }
                    : undefined,
              },
              cards: items.map((item) => {
                if (
                  !item ||
                  !item.cardtitle ||
                  !item.cardtitle.text ||
                  !item.cardsubtitle ||
                  !item.cardsubtitle.text ||
                  !item.cardimage ||
                  !item.cardimage.url
                ) {
                  throw new Error('Invalid home_english project_cards slice.')
                }

                return {
                  title: item.cardtitle.text,
                  subtitle: item.cardsubtitle.text,
                  background: { alt: item.cardimage.alt || '', url: item.cardimage.url },
                  linkUrl: item.cardlink?.url || undefined,
                }
              }),
            },
          }
        }
        case 'brands_block': {
          if (!items) {
            throw new Error('Invalid home_english brands_block slice.')
          }

          return {
            type: HomeSliceType.BRANDS_BLOCK,
            content: {
              brands: items.map((item) => {
                if (
                  !item ||
                  !item.logo ||
                  !item.logo.url ||
                  !item.title ||
                  !item.title.text ||
                  !item.subtitle ||
                  !item.subtitle.text ||
                  !item.image ||
                  !item.image.url ||
                  !item.linkurl ||
                  !item.linkurl.url
                ) {
                  throw new Error('Invalid home_english brands_block slice.')
                }

                return {
                  logo: { alt: item.logo.alt || '', url: item.logo.url },
                  title: item.title.text,
                  subtitle: item.subtitle.text,
                  background: { alt: item.image.alt || '', url: item.image.url },
                  linkUrl: item.linkurl.url,
                }
              }),
            },
          }
        }
        case 'video': {
          if (!primary && !primary.youtubeembed && !primary.youtubeembed.text) {
            throw new Error('Invalid home_english video slice.')
          }

          return {
            type: HomeSliceType.VIDEO,
            content: { embed: primary.youtubeembed.text, description: primary.description?.text || '' },
          }
        }
        default: {
          throw new Error('Invalid home_english node.')
        }
      }
    }),
  }
}
