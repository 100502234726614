import React from 'react'
import { graphql } from 'gatsby'

import { TemplateHomeEnglishDataQuery } from '../../../gatsby-types'
import { getTitle } from '../../utils/prismic'
import LayoutEnglish from '../../layouts/english'
import Body from '../../components/home/body'
import { transformSeo } from '../../utils/seo-transformer'

import { transformHomeEnglishNode } from './transformer'

interface IProps {
  data: TemplateHomeEnglishDataQuery
}

export default function Home({ data: { prismicHomeEnglish } }: IProps) {
  const contentPage = transformHomeEnglishNode(prismicHomeEnglish)
  const seoContent =
    prismicHomeEnglish && prismicHomeEnglish.data && prismicHomeEnglish.data.seo && prismicHomeEnglish.data.seo[0]
      ? transformSeo(prismicHomeEnglish.data.seo[0])
      : undefined

  const { name, slices } = contentPage

  return (
    <LayoutEnglish seoContent={seoContent} title={getTitle(name)}>
      <Body english slices={slices} />
    </LayoutEnglish>
  )
}

export const query = graphql`
  query TemplateHomeEnglishData {
    prismicHomeEnglish {
      data {
        name {
          text
        }
        body {
          ... on PrismicHomeEnglishBodyMainTitleBlock {
            slice_type
            primary {
              title {
                html
              }
              subtitle {
                text
              }
              leftlinklabel {
                text
              }
              leftlinkurl {
                url
              }
              rightlinklabel {
                text
              }
              rightlinkurl {
                url
              }
            }
          }
          ... on PrismicHomeEnglishBodyInlineBlock {
            slice_type
            primary {
              title {
                text
              }
              subtitle {
                text
              }
              text {
                html
              }
              linklabel {
                text
              }
              linkurl {
                url
              }
            }
          }
          ... on PrismicHomeEnglishBodyBanner {
            slice_type
            primary {
              video {
                url
              }
            }
          }
          ... on PrismicHomeEnglishBodyEdtechBlock {
            id
            slice_type
            primary {
              lefttitle {
                text
              }
              lefttext {
                html
              }
              righttitle {
                text
              }
              righttext {
                html
              }
            }
          }
          ... on PrismicHomeEnglishBodyProjectCards {
            slice_type
            primary {
              lefttitle {
                text
              }
              subtitle {
                text
              }
              lefttext {
                text
              }
              righttext {
                html
              }
              linklabel {
                text
              }
              linkurl {
                url
              }
            }
            items {
              cardtitle {
                text
              }
              cardsubtitle {
                text
              }
              cardimage {
                alt
                url
              }
              cardlink {
                url
              }
            }
          }
          ... on PrismicHomeEnglishBodyBrandsBlock {
            slice_type
            items {
              logo {
                alt
                url
              }
              title {
                text
              }
              subtitle {
                text
              }
              image {
                alt
                url
              }
              linkurl {
                url
              }
            }
          }
          ... on PrismicHomeEnglishBodyVideo {
            slice_type
            primary {
              youtubeembed {
                text
              }
              description {
                text
              }
            }
          }
        }
        seo {
          ... on PrismicHomeEnglishSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
  }
`

Home.query = query
